<template>
  <div class="container-body">
    <topMb class="is-mb" />
    <top class="is-pc" />
    <div class="box main-content">
      <router-view></router-view>
    </div>
    <bottomMb class="is-mb footer" />
    <bottom class="is-pc footer" />
  </div>
</template>

<script>
import top from '@/components/top'
import topMb from '@/components/top-moblie'
import bottom from '@/components/bottom'
import bottomMb from '@/components/bottom-moblie'

// import reserve from "@/views/reserve/reserve";
export default {
  name: 'home',
  components: {
    top,
    bottom,
    topMb,
    bottomMb,
    // reserve,
  },
  created() {
    if (!localStorage.getItem('locale')) {
      localStorage.setItem('locale', 'zh_tw')
    }
  },
  data() {
    return {}
  },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
  },
}
</script>

<style scoped>
@import "~@/assets/css/index.scss";
.box {
  min-height: 500px;
  /* margin-bottom: 20px; */
}
.is-pc {
  display: block;
}
.is-mb {
  display: none;
}
@media (max-width: 766px) {
  .box {
    min-height: 500px;
    margin-bottom: 0px;
  }
  .is-pc {
    display: none;
  }
  .is-mb {
    display: block;
  }
}
</style>
