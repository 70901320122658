<template>
<!--  -->
  <div v-if="paths !== '/home' && paths !== '/filmcontest'" class="all-container bottom">
    <div class="container-mb">
      <div class="bottom-box">
        <p class="bottom-content">
          <el-link href="/policy.html" target="_blank" style="color:#fff">Privacy Policy</el-link><br/>
          Copyright © Tripellet.com , All rights reserved
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("@/assets/images/logo-03.png")
    };
  },
  computed: {
    paths() {
      return this.$route.path
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.bottom {
  width: 100%;
  height: 80px;
  background: #313131;
  .bottom-box {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    .bottom-logo {
      width: 200px;
    }
    .bottom-content {
      margin-left: 30px;
      flex: 1;
      color: #fff;
      text-align: center;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
