<template>
  <div v-if="paths !== '/home' && paths !== '/filmcontest' && paths !== '/aboutus'">
    <!--  -->
    <div v-if="!showFlag" class="container-mb">
      <div class="top-box">
        <el-image :src="logo" class="top-logo" @click="toHome()" />
        <div>
          <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
            <div class="avatar-wrapper flex-box">
              <el-image :src="lanLogo" class="top-menu" lazy />
              <span style="display:block;margin-left: 10px;">{{lan}}</span>
            </div>
            <el-dropdown-menu slot="dropdown" style="top:50px">
              <el-dropdown-item divided @click.native="changLan('ja')">
                <span style="display:block;">日本語</span>
              </el-dropdown-item>
              <el-dropdown-item  divided @click.native="changLan('zh')">
                <span style="display:block;">简体中文</span>
              </el-dropdown-item>
              <el-dropdown-item  divided @click.native="changLan('zh_tw')">
                <span style="display:block;">繁體中文</span>
              </el-dropdown-item>
              <el-dropdown-item divided @click.native="changLan('en')">
                <span style="display:block;">English</span>
              </el-dropdown-item>
              <el-dropdown-item  divided @click.native="changLan('ko')">
                <span style="display:block;">한국어</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div v-else class="container-mb">
      <div class="top-box2">
        <div class="container2">
          <el-image :src="logo" class="top-logo" @click="toHome()" />
          <div>
            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
              <div class="avatar-wrapper flex-box">
                <el-image :src="lanLogo" class="top-menu" lazy />
                <span style="display:block;margin-left: 10px;">{{lan}}</span>
              </div>

              <el-dropdown-menu slot="dropdown" style="top:50px">
                <el-dropdown-item divided @click.native="changLan('ja')">
                  <span style="display:block;">日本語</span>
                </el-dropdown-item>
                <el-dropdown-item  divided @click.native="changLan('zh')">
                  <span style="display:block;">简体中文</span>
                </el-dropdown-item>
                <el-dropdown-item  divided @click.native="changLan('zh_tw')">
                  <span style="display:block;">繁體中文</span>
                </el-dropdown-item>
                <el-dropdown-item divided @click.native="changLan('en')">
                  <span style="display:block;">English</span>
                </el-dropdown-item>
                <el-dropdown-item  divided @click.native="changLan('ko')">
                  <span style="display:block;">한국어</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reloadMessage } from "@/lang";
import { homeLanList } from '@/utils/public_data'
export default {
  data() {
    return {
      logo: require("@/assets/images/logo-03.png"),
      lanLogo: require("@/assets/images/lan.png"),
      banner: require("@/assets/images/banner.png"),
      showFlag: false,
      lanList: homeLanList(),
    };
  },
  computed: {
    paths() {
      return this.$route.path
    },
    lan() {
      return this.lanList[localStorage.getItem('locale')]
    },

  },
  mounted() {
    window.addEventListener("scroll", this.showTop);
  },
  methods: {
    changLan(val) {
      // 设置语言
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
      reloadMessage();
      this.$router.go(0);
    },
    showTop() {
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop > 5
      ) {
        this.showFlag = true;
      } else {
        this.showFlag = false;
      }
    },
    toHome() {
      this.$router.push({ path: "/home" });
    }
  }
};
</script>
<style lang="scss" scoped>
// ::v-deep .el-dropdown-menu__item.el-dropdown-menu__item--divided:hover{
//     color: #ffbb2c ;
//     background-color: #fff4dc;
// }
.top-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  .top-logo {
    width: 100px;
    cursor: pointer;
  }
  .top-menu {
    width: 24px;
  }
}

.top-box2 {
  // padding: 0 20% 0 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  height: 60px;
  background: #fff;
  z-index: 1000;
  -moz-box-shadow: 0px 2px 12px #ddd;
  -webkit-box-shadow: 0px 2px 12px #ddd;
  box-shadow: 0px 2px 8px #ddd;
  .container2 {
    width: 96%;
    margin: 0 auto;
    display: flex;
    padding: 0 10px;
    box-sizing: border-box;
    height: 60px;
    // line-height: 60px;
    justify-content: space-between;
    align-items: center;
    .top-logo {
      width: 100px;
    }
    .top-menu {
      width: 24px;
    }
  }
}
.top-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 280px;
  background: url("../assets/images/banner.png");
  .banner-title1 {
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .banner-title2 {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
  }
}
.flex-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
